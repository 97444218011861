<template>
  <div class="FundManagement_I">
    <!-- 搜索导航 -->
    <div class="capitalNav">
      <div class="capitalLi"> 
        <div class="capitalLiName">转账类型：</div>
        <span :class="actit == '0' ? 'capitalLiActive': ''" @click="AUOP('0')">全部</span>
        <span :class="actit == 'redpack' ? 'capitalLiActive': ''" @click="AUOP('redpack')">微信红包</span>
        <span :class="actit == 'transfer' ? 'capitalLiActive': ''" @click="AUOP('transfer')">微信零钱</span>
        <span :class="actit == 'paybank' ? 'capitalLiActive': ''" @click="AUOP('paybank')">银行卡(个人)</span>
      </div>
      <div class="capitalLi"> 
        <div class="capitalLiName">转账状态：</div>
        <span :class="actit2 == 0 ? 'capitalLiActive': ''" @click="AUOP2(0)">全部</span>
        <span :class="actit2 == 20 ? 'capitalLiActive': ''" @click="AUOP2(20)">转账成功</span>
        <span :class="actit2 == 30 ? 'capitalLiActive': ''" @click="AUOP2(30)">转账失败</span>
      </div>
      <div>
        <div class="NavLi">
          <div class="NavLiName">核定月份：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>
    
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('finance:withdraw:payment')"><i class="el-icon-plus"></i>发起转账</div>
        <div class="NavLiDelte" @click="BatchDel()" v-if="TextMoMo('workbench:sales:middle:read')"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入商户订单号">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="select_change2">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID"  width="55" align="center"></el-table-column>
        <el-table-column align="center" prop="realname" label="收款人"></el-table-column>
        <el-table-column align="center" prop="createtime" label="转账时间"></el-table-column>
        <el-table-column align="center" prop="pay_type_text" label="转账类型"></el-table-column>
        <el-table-column align="center" prop="staff.realname" label="操作人"></el-table-column>
        <el-table-column align="center" prop="pay_status_text" label="转账状态"></el-table-column>
        <el-table-column align="center" prop="pay_status" label="金额"></el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template #default="scope">
            <div class="el_butto">
              <el-button type="text" @click="HandleSee(scope.row)" >查看</el-button>
            </div>
            <div class="el_butto">
              <el-button type="text" @click="seeEdit(scope.row)" >编辑</el-button>
            </div>
            <div class="el_butto rere">
                <el-button
                type="text"
                class="red" v-if="TextMoMo('finance:withdraw:columns')"
                @click="HandleDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!--变更部门-->
    <el-dialog :title="titleMath" v-model="editVisible" width="33%"> 
      <div class="headNavs" style="padding:0;">
        <el-menu
          :default-active="acndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect2">
          <el-menu-item index="1">微信红包</el-menu-item>
          <el-menu-item index="2">微信零钱</el-menu-item>
          <el-menu-item index="3">微信转卡</el-menu-item>
        </el-menu>

        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row" label-width="120px" >
        <el-form-item class="EditNewFormBtns" label="收款方户名：" prop="realname">
          <el-input v-model="Row.realname" placeholder="请填写持卡人姓名" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="openid" label="收款方openid：" v-if="isShow == 1">
          <el-input v-model="Row.openid" placeholder="请填写收款人openid" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="bank_card" label="收款方账号：" v-if="isShow == 3">
          <el-input v-model="Row.bank_card" placeholder="只支持向个人银行账户付款" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="bank_open" label="收款开户行：" v-if="isShow == 3">
          <el-select  v-model="Row.bank_open" placeholder="请选择正确的开户行信息" @change="Chang_ses" :disabled="control">
            <el-option  v-for="item in bankList" :key="item" :label="item" :value="item" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="money" label="打款金额：">
          <el-input type="number" v-model="Row.money" placeholder="请填写需要付款的金额" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="reason" label="打款事由：">
          <el-input v-model="Row.reason" placeholder="请填写打款事由" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="password" label="密码：">
          <el-input v-model="Row.password" placeholder="请输入密码" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="remark" label="备注：">
          <el-input type="textarea" v-model="Row.remark" placeholder="请填写备注" :disabled="control"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="XX == 'xinjian'" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="XX == 'bianji'" class="FormBtnsQuery" @click="BTN_Hb()">保存</div>
            <div class="FormBtnsClose" @click="editVisible = false">取消</div>
          </div>
        </el-form-item>
        </el-form>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
    data(){
      return{
        tableData:[],
        bankList:[],
        selection_arr2:[],
        page: {   // 分页
          currentPage: 1,
          pageSize: 10,
        },
        //搜索参数
        search: {
          pay_type:'',
          pay_status:'',
          keyword: "",
          time: {
            min:"",
            max:"",
          },
        },
        rules:{
          realname: [{ required: true, message: '请填写持卡人姓名', trigger: 'blur' }],
          openid: [{ required: true, message: '请填写收款人openid', trigger: 'blur' }],
          bank_card: [{ required: true, message: '只支持向个人银行账户付款', trigger: 'blur' }],
          bank_open: [{ required: true, message: '请选择正确的开户行信息', trigger: 'change' }],
          money: [{ required: true, message: '请填写需要付款的金额', trigger: 'blur' }],
          reason: [{ required: true, message: '请填写打款事由', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          remark: [{ required: true, message: '请填写备注', trigger: 'blur' }],
        },
        Row:{
          staff_id:'',
          pay_type:'',
          password:'',
          realname:'',
          openid:'',
          bank_card:'',
          bank_open:'',
          money:'',
          reason:'',
          remark:'',
        },
        total:'',
        actit:0,
        actit2:0,
        isShow:'',
        staffId:'',
        payType:'',
        isInfo:'',
        editVisible:false,
        control:false,
        acndex:'1',
        XX:'',
        titleMath:''
      }
    },
    mounted(){
        var that = this
        that.loadTable()
    },
    methods:{
        // 获取列表数据
        loadTable() {
          var that = this
          axios({
            method: 'post',
            url: '/admin/finance/withdraw/index',
            data: {
              page: that.page.currentPage,
              limit: that.page.pageSize,
              filter: that.search,
            },
          }).then(function (res) {
            that.tableData = res.data.data.rows
            that.staffId = res.data.data.rows ? res.data.data.rows[0].staff_id : ''
            that.bankList = res.data.data.bankList
            that.total = res.data.data.total
          })
        },
        //搜索
        BtnSearch(e) {
          var that = this
          that.page.currentPage = 1;
          if( e == false){
            that.actit = 0
            that.actit2 = 0
            that.search = {
              keyword: "",
              time: {
                min:"",
                max:"",
              },
            }
          }
          var time = ''
          var time2 = ''
          if (that.search.time.min != '' ) {
            var date = new Date(that.search.time.min)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            time = y + '-' + m + '-' + d
          }
          if (that.search.time.max != '') {
            var date2 = new Date(that.search.time.max)
            var y2 = date2.getFullYear()
            var m2 = date2.getMonth() + 1
            m2 = m2 < 10 ? ('0' + m2) : m2
            var d2 = date2.getDate()
            d2 = d2 < 10 ? ('0' + d2) : d2
            time2 = y2 + '-' + m2 + '-' + d2
          }
          axios({
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
              filter:{
                pay_type: that.actit,
                pay_status: that.actit2,
                time:{
                  min:time,
                  max:time2,
                },
                keyword: that.search.keyword
              }
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
              }
          })
          
        },
        AUOP2(e) {
          var that = this
          that.actit2 = e
          that.BtnSearch()
        },
        AUOP(e) {
          console.log(e)
          var that = this
          that.actit = e
          that.BtnSearch()
        },
        // 创建打开
        showAdd() {
          var that = this
          that.editVisible = true;
          that.control = false
          that.Row = {};
          that.isInfo = 1 
          that.isShow = 1
          that.acndex = 1
          that.XX = 'xinjian'
          that.payType = 'redpack'
        },
        //新增
        BtnAdd() {
          var that = this
          that.$refs['Row'].validate((valid) =>{
            if (valid) {
              axios({
                method: 'post',
                url: '/admin/finance/withdraw/payment',
                data: {
                  row: {
                    staff_id: that.staffId,
                    pay_type: that.payType,
                    password:that.Row.password,
                    realname:that.Row.realname,
                    openid:that.Row.openid,
                    bank_card:that.Row.bank_card ? that.Row.bank_card : '',
                    bank_open:that.Row.bank_open ? that.Row.bank_open : '',
                    money:that.Row.money,
                    reason:that.Row.reason,
                    remark:that.Row.remark,
                  }
                },
              }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                  that.$message.success(res.data.msg);
                  that.editVisible = false
                  that.loadTable();
                }
              })
            }
          })     
        },
        //删除
        HandleDel(data) {
          var that = this
          var arr = []
          arr.push(data.id)
          that.$confirm("确定要删除吗？", "提示", {
            type: "primary"
          }).then(() => {
            axios({
              method: 'DELETE',
              url: '/admin/finance/withdraw/del',
              data: {
                ids: arr
              }
            }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable();
              }
            })
          })
        },
        //批量删除
        BatchDel(){
          var that = this
          that.$confirm("确定要删除吗？", "提示", {
              type: "warning"
          }).then(() => {
              var arr = []
              for (let i = 0; i < that.selection_arr2.length; i++) {
                const element = that.selection_arr2[i];
                arr.push(element)
              }
              axios.delete('/admin/finance/withdraw/del',{   // 模块
                params:{
                      ids: arr
                }
              }).then(function (res) {
                   if (res.code == 0) {
                        that.$message.warning(res.data.msg);
                  } else{
                      that.loadTable()
                  }
              })
          })
        },
        HandleSee(data){
          var that = this 
          that.editVisible = true
          that.control = true
          that.XX = ''
          that.titleMath = '查看转账'
          that.isInfo = 2
          axios.get('/admin/finance/withdraw/read', {  
            params: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              alert('请求失败');
            } else {
              that.Row = {
                staff_id:res.data.data.row.staff_id,
                pay_type:res.data.data.row.pay_type,
                password:res.data.data.row.password,
                realname:res.data.data.row.realname,
                openid:res.data.data.row.openid,
                bank_card:parseInt(res.data.data.row.bank_card),
                bank_open:res.data.data.row.bank_open,
                money:res.data.data.row.money,
                reason:res.data.data.row.reason,
                remark:res.data.data.row.remark,
              }
              if(res.data.data.row.pay_type == 'transfer'){
                that.isShow = 1
                that.acndex = 2
              } else if(res.data.data.row.pay_type == 'redpack'){
                that.isShow = 1
                that.acndex = 1
              } else if(res.data.data.row.pay_type == 'paybank'){
                that.isShow = 3
                that.acndex = 3
              }
            }
          })
        },
        //编辑
        seeEdit(data){
          var that = this 
          that.editVisible = true
          that.control = false
          that.titleMath = '编辑转账'
          that.XX = 'bianji'
          that.isInfo = 2
          axios.get('/admin/finance/withdraw/read', {  
            params: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              alert('请求失败');
            } else {
              that.Row = {
                staff_id:res.data.data.row.staff_id,
                pay_type:res.data.data.row.pay_type,
                password:res.data.data.row.password,
                realname:res.data.data.row.realname,
                openid:res.data.data.row.openid,
                bank_card:parseInt(res.data.data.row.bank_card),
                bank_open:res.data.data.row.bank_open,
                money:res.data.data.row.money,
                reason:res.data.data.row.reason,
                remark:res.data.data.row.remark,
              }
              if(res.data.data.row.pay_type == 'transfer'){
                that.isShow = 1
                that.acndex = 2
              } else if(res.data.data.row.pay_type == 'redpack'){
                that.isShow = 1
                that.acndex = 1
              } else if(res.data.data.row.pay_type == 'paybank'){
                that.isShow = 3
                that.acndex = 3
              }
            }
          })
        },
        handleSelect2(e){
          var that = this
          that.acndex = e
          if(e == 1){
            that.isShow = 1
            that.payType = 'redpack'
          } else if(e == 2){
            that.isShow = 1
            that.payType = 'transfer'
          }else if(e == 3){
            that.isShow = 3
            that.payType = 'paybank'
          }
        },
        select_change2(sel) {   // 资金管理勾选
          var that = this
          var searr = []
          for (let i = 0; i < sel.length; i++) {
            const ele = sel[i].id;
            searr.push(ele)
          }
          that.selection_arr2 = searr
        },
        // 分页
        handleCurrentChange(page) {
          var that = this
          that.page.currentPage = page; 
          that.loadTable();
        },
        TextMoMo(e) {
          var arr = TextMoth.getData(e)
          return arr  
        },
    }
}
</script>

<style scoped>
.EditNewForm{
  padding: 24px;
}
.FormBtns{
  padding: 0;
}
</style>