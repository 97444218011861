<template>
  <div class="ControlOverInvoices_I">
    <!-- 搜索导航 -->
    <div class="capitalNav">
      <div class="capitalLi"> 
        <div class="capitalLiName">发票类型：</div>
        <span :class="P_actitA_1 == i ? 'capitalLiActive': ''" @click="P_AUOP1(i)" v-for="(item, i) in Nature" :key="i">{{item}}</span>
      </div>
      <div class="capitalLi"> 
        <div class="capitalLiName">凭证类型：</div>
        <span :class="P_actitA_2 == i ? 'capitalLiActive': ''" @click="P_AUOP2(i)" v-for="(item, i) in Invoice_type" :key="i">{{item}}</span>
      </div>
      <div class="capitalLi"> 
        <div class="capitalLiName">发票类型：</div>
        <span :class="P_actitA_3 == i ? 'capitalLiActive': ''" @click="P_AUOP3(i)" v-for="(item, i) in Invoice_status" :key="i">{{item}}</span>
      </div>
      <div>
        <div class="NavLi">
          <div class="NavLiName">转出日期：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入申请人">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="selection_arr2">
        <el-table-column type="selection"  width="55"  align="center"></el-table-column>
        <el-table-column prop="id"  label="ID" width="55" align="center"></el-table-column>
        <el-table-column align="center" prop="user.nickname" label="申请人"></el-table-column>
        <el-table-column align="center" prop="info.invoice_type_text" label="发票类型"></el-table-column>
        <el-table-column align="center" prop="nature_text" label="凭证类型"></el-table-column>
        <el-table-column align="center" prop="apply_time" label="申请日期"></el-table-column>
        <el-table-column align="center" prop="price" label="开票金额"></el-table-column>
        <el-table-column align="center" prop="bill_time" label="开票日期"></el-table-column>
        <el-table-column align="center" prop="invoice_status_text" label="开票状态"></el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template #default="scope">
            <div class="el_butto">
              <el-button type="text"  @click="HandleSee(scope.row)" v-if="TextMoMo('order/invoice:read')">查看</el-button>
            </div>
            <div class="el_butto">
              <el-button type="text"  @click="HandleEdit(scope.row)" v-if="TextMoMo('order/invoice:edit')">编辑</el-button>
            </div>
            <div class="el_butto rere">
              <el-button
              type="text"
              class="red" v-if="TextMoMo('order/invoice:del')"
              @click="handDelete3(scope.row)"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>


    <!-- 编辑新增 -->
    <div class="show" v-if="editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>发票操作</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row">
          <div class="invoice">
            <div class="invoiceLi">
              <div>申请人</div>
              <div>{{User.nickname}}</div>
            </div>
            <div class="invoiceLi">
              <div>订单信息</div>
              <div>{{Order.order_no}}</div>
            </div>
            <div class="invoiceLi">
              <div>开票金额</div>
              <div>{{Kaipiao.price}}</div>
            </div>
            <div class="invoiceLi">
              <div>发票类型</div>
              <div>{{Info.invoice_type_text}}</div>
            </div>
            <div class="invoiceLi">
              <div>凭证类型</div>
              <div>{{Kaipiao.nature_text}}</div>
            </div>
            <div class="invoiceLi">
              <div>申请时间</div>
              <div>{{Kaipiao.apply_time}}</div>
            </div>
            <div class="invoiceLi" style="width: 100%;">
              <div class="LiAAAA">邮寄地址</div>
              <div>{{Address.province}}&nbsp;{{Address.city}}&nbsp;{{Address.town}}&nbsp;{{Address.street}}</div>
            </div>
          </div>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName mation">开票情况：</div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="invoice_status">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>开票状态：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.invoice_status" placeholder="请选择销售类型" required  :disabled="control">
                <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="drawer_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>开票人：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.drawer_id" placeholder="请选择开票人" required :disabled="control">
                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="phone">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>开票时间：</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="Row.bill_time" required  type="datetime" placeholder="请选择开票时间" :disabled="control" />
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="qq">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>开票金额：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.bill_money" required placeholder="请输入开票金额" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-if="control ==  true">
            <div class="EditNewFormItemName" style="margin-bottom: 10px;">查看发票：</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <img v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item" class="Avatar" />
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-else>
            <div class="EditNewFormItemName" style="margin-bottom: 10px;">查看发票：</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <img v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item" class="Avatar" />
                <el-upload
                  ref="upload-images"
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :on-success="UP_Success"
                  :on-change = "Up_chang"
                  :on-remove="handleRemove"
                  :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm == 2" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      tableData:[],
      selection_arr2:[],
      StaFFList:[],
      full_avatar:[],
      full_avatar2:[],
      Row_Eurl:[], //图片上传成功存储
      Nature:{},
      Invoice_type:{},
      Invoice_status:{},
      User:{},
      Order:{},
      Info:{},
      Kaipiao:{},
      Address:{},
      control:false,
      MMD:[{id:1,name:"待批复"},{id:2,name:"同意开票"},{id:3,name:"拒绝申请"},{id:4,name:"已开票"}],
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      }, 
      total:'',
      search: {  //搜索参数
        nature:'',
        invoice_type:'',
        invoice_status:'',
        keyword: "",
        time: {
          min:"",
          max:"",
        },
      },
      Row:{
        bill_money:'',
        invoice_status:'',
        bill_time:'',
        drawer_id:'',
        images:[],
      },
      P_actitA_1:0,
      P_actitA_2:0,
      P_actitA_3:0,
      editId:'',
      resetForm:1,
      editPanel:false,
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.status()
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/invoice/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows  
        that.total = res.data.data.total
      })
    },
    //搜索列表
    status(){
      var that = this
      axios.get('/admin/order/invoice/statisticsColumns',{  
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
            that.Nature = res.data.data.nature
            that.Invoice_type = res.data.data.invoice_type
            that.Invoice_status = res.data.data.invoice_status
            that.Nature[0] = '全部'
            that.Invoice_type[0] = '全部'
            that.Invoice_status[0] = '全部'
        }
      }) 
    },
    HandleSee(data){
      var that = this
      that.control = true
      that.editPanel = true;
      that.resetForm = 0
      that.editId = data.id
      that.yuangong()
      axios.get('/admin/order/invoice/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) { 
        that.full_avatar = res.data.data.row.imgs_url
        that.Row.bill_money = parseFloat(res.data.data.row.bill_money).toFixed(0)
        that.Row.invoice_status = res.data.data.row.invoice_status
        that.Row.bill_time = res.data.data.row.bill_time
        that.Row.drawer_id = parseInt(res.data.data.row.drawer_id)
        that.Kaipiao = res.data.data.row
        that.User = res.data.data.user ? res.data.data.user : {}
        that.Order = res.data.data.order ? res.data.data.order : {}
        that.Info = res.data.data.info ? res.data.data.info : {}
        that.Address = res.data.data.address ? res.data.data.address : "暂无地址"
      })
    },
    //获取编辑内容
    HandleEdit(data) {
      var that = this
      that.editPanel = true;
      that.control = false
      that.resetForm = 2
      that.editId = data.id
      that.yuangong()
      axios.get('/admin/order/invoice/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) { 
        that.full_avatar = res.data.data.row.imgs_url
        that.Row.bill_money = parseFloat(res.data.data.row.bill_money).toFixed(0)
        that.Row.invoice_status = res.data.data.row.invoice_status
        that.Row.bill_time = res.data.data.row.bill_time
        that.Row.drawer_id = parseInt(res.data.data.row.drawer_id)
        that.Kaipiao = res.data.data.row
        that.User = res.data.data.user ? res.data.data.user : {}
        that.Order = res.data.data.order ? res.data.data.order : {}
        that.Info = res.data.data.info ? res.data.data.info : {}
        that.Address = res.data.data.address ? res.data.data.address : "暂无地址"
      })
    },
    handDelete3(e) {       // 删除发票
        var that = this
        var arr  = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/order/invoice/del',
            data: {
              ids: arr
            },
          }).then(function (res) {
            that.$message.success(res.data.msg);
            that.loadTable()
          })                 
        })
    },
    // 编辑保存
    BtnEdit() {
      var that = this
      var date = new Date(that.Row.bill_time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      axios({
        method: 'put',
        url: '/admin/order/invoice/edit',
        data: {
          id: that.editId,
          row: {
            bill_money: that.Row.bill_money ,
            invoice_status: that.Row.invoice_status,
            bill_time: time,
            drawer_id: that.Row.drawer_id,
            images: that.Row_Eurl,
          }, 
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
          that.editPanel = false
        }
      })
    },
    //关闭按钮
    Close() {
      var that = this
      that.editPanel = false;
      that.Row = {}
    },
    //员工列表
    yuangong(){
      var that = this
      axios.get('/admin/ajax/staffList',{   // 全部员工列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StaFFList = res.data.data
        }
      }) 
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.P_actitA_1 = 0
        that.P_actitA_2 = 0
        that.P_actitA_3 = 0
        that.search = {
          keyword: "",
          time: {
            min:"",
            max:"",
          },
        }
      }
      var time = ''
      var time2 = ''
      if (that.search.time.min != '' ) {
        var date = new Date(that.search.time.min)
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        var d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        time = y + '-' + m + '-' + d
      }
      if (that.search.time.max != '') {
        var date2 = new Date(that.search.time.max)
        var y2 = date2.getFullYear()
        var m2 = date2.getMonth() + 1
        m2 = m2 < 10 ? ('0' + m2) : m2
        var d2 = date2.getDate()
        d2 = d2 < 10 ? ('0' + d2) : d2
        time2 = y2 + '-' + m2 + '-' + d2
      }
      axios({
        method: 'post',
        url: '/admin/order/invoice/index',    
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter:{
            nature: that.P_actitA_1,
            invoice_type: that.P_actitA_2,
            invoice_status: that.P_actitA_3,
            time:{
              min:time,
              max:time2,
            },
            keyword: that.search.keyword
          }
        },
      }).then(function (res) {
        if (res.code == 401) {
           that.$router.push('/Login');
        } else {
          that.total = res.data.data.total
          that.tableData = res.data.data.rows
        }
      })
          
    },
    P_AUOP1(e) {
      var that = this
      that.P_actitA_1 = e    
      that.BtnSearch()
    },
    P_AUOP2(e) {
      var that = this
      that.P_actitA_2 = e    
      that.BtnSearch()  
    },
    P_AUOP3(e) {
      var that = this
      that.P_actitA_3 = e   
      that.BtnSearch()  
    },
    // 分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page; 
      that.loadTable();
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      that.Row_Eurl.push(e.data.url)
      console.log(that.Row_Eurl)
    },
    handleRemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        that.Row_Eurl.push(element.response.data.url)
      }
      console.log(that.Row_Eurl)
    },
    handlePictureCardPreview(file) {   //获取图片
      var that = this
      that.full_image = file.url;
    },
  }
}
</script>

<style scoped>
.invoice{
  display: flex;
  flex-wrap: wrap;
  width: 94.5%;
  border: 1px solid #EBEEF5;
  border-right: 0;
  border-bottom: 0;
}
.invoiceLi{
  width: 49.8%;
  display: flex;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
}
.invoiceLi div:nth-child(1){
  background-color: #f5f7fa;
  width: 40%;
  text-align: center;
}
.invoiceLi div:nth-child(2){
  text-indent: 1.5em;
}
.invoiceLi .LiAAAA{
  width: 20% !important;
}
.mation{
  margin: 20px 0 0;
  
}
</style>