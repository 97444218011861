<template>
  <div class="Finance_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">工资单</el-menu-item>
          <el-menu-item index="2">账单核对</el-menu-item>
          <el-menu-item index="3">资金管理</el-menu-item>
          <el-menu-item index="4">发票管理</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
    </div>
    <ControlOverInvoices v-if="isShow ==  4"></ControlOverInvoices><!-- 发票管理 -->
    <FundManagement v-if="isShow ==  3"></FundManagement><!-- 资金管理 -->
    <Reconciliationofbills v-if="isShow ==  2"></Reconciliationofbills><!-- 账单核对 -->
    <div v-if="isShow ==  1">
      <!-- 搜索导航 -->
      <div class="searchNav">
        <div class="NavLi">
          <div class="NavLiName">工资月份：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
        </div>
        <div class="NavLi" style="flex:1.8">
          <div class="NavLiName">工资额：</div>
          <div class="NavLiIpt">
            <el-input class="Salary" v-model="search.money.min" placeholder="输入具体值"></el-input>
            <span class="timeSpan">至</span>
            <el-input class="Salary" v-model="search.money.max" placeholder="输入具体值"></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">员工：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.staff_id" placeholder="请选择">
              <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname"  :value="item.id" ></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiIpt Ipy">
            <el-input v-model="name" disabled></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiQuery" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="TablesList">
        <div class="TablesADD">
          <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('finance:salary:add')"><i class="el-icon-plus"></i>创建</div>
          <div class="TableIpt"> 
            <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入内容">
            <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange">
          <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
          <el-table-column align="center" prop="staff.realname" label="员工"></el-table-column>
          <el-table-column align="center" prop="month" label="发放月"></el-table-column>
          <el-table-column align="center" prop="go_clock_num" label="本月出勤(天)"></el-table-column>
          <el-table-column align="center" prop="total_salary" label="应发工资"></el-table-column>
          <el-table-column align="center" prop="real_salary" label="实发工资"></el-table-column>
          <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
          <el-table-column align="center" prop="back_status_text" label="员工反馈"></el-table-column>
          <el-table-column align="center" label="操作" width="300">
            <template #default="scope">
              <div class="el_butto">
                <el-button
                type="text" v-if="TextMoMo('finance:salary:read')"
                @click="HandleSee(scope.row)"
                >查看</el-button>
                <el-button
                type="text" v-if="!TextMoMo('finance:salary:read')"
                >查看</el-button>
              </div>
              <div class="el_butto">
                <el-button
                type="text" v-if="TextMoMo('finance:salary:edit')"
                @click="HandleEdit(scope.row)"
                >编辑</el-button>
              </div>
              <div class="el_butto"  v-if="TextMoMo('finance:salary:confirm')">
                <el-button
                v-if="scope.row.back_status == 10"
                type="text"
                @click="handSub(scope.row)"
                >确认</el-button>
                <el-button v-if="scope.row.back_status != 10"
                type="text"
                >已确认</el-button>
              </div>
              <div class="el_butto rere">
                <el-button
                type="text" v-if="TextMoMo('finance:salary:del')"
                class="red"
                @click="HandleDel(scope.row)"
                >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 编辑新增 -->
    <div class="show" v-if="ruleFormRef2">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName mation">基本信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="staff_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>员工</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.staff_id" placeholder="请选择员工姓名" @focus="Foccewe1" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="People_jj2">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>部门</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.People_jj2" placeholder="请选择部门" @focus="Foccewe2" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="People_jj3">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>职务</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.People_jj3" placeholder="请填写职务" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="time">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>工资月份</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="Row.time" placeholder="选择月份" :disabled="control"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName mation">工资信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="need_clock_num">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>应出勤天数</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.need_clock_num" placeholder="请输入应出勤天数" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="go_clock_num">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>实际出勤天数</div>
            <div class="EditNewFormItemIpt">
              <el-input  v-model="Row.go_clock_num" placeholder="请输入实际出勤天数" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="basic">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>基本工资</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.basic" placeholder="请输入基本工资" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="subsidy">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>综合补贴</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.subsidy" placeholder="请填写综合补贴" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="performance">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>绩效工资</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.performance" placeholder="请填写绩效工资" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="percentage">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>总提成</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.percentage" placeholder="请填写总提成" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="percentage_month">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>当月实发提成</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.percentage_month" placeholder="请填写当月实发提成" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="percentage_other">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>剩余提成</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.percentage_other" placeholder="请填写剩余提成" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="full_attendence_reward">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>全勤奖</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.full_attendence_reward" placeholder="请填写全勤奖" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="bonus">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>奖金</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.bonus" placeholder="请填写奖金" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="deduct_clock">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>考勤扣除</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.deduct_clock" placeholder="请填写考勤扣除" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="accumulation_fund">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>公积金扣除</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.accumulation_fund" placeholder="请填写公积金扣除" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="insurance">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>社保代扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.insurance" placeholder="请填写社保代扣" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="deduct_percentage">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>提成扣除</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.deduct_percentage" placeholder="请填写提成扣除" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="income_tax">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>所得税扣除</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.income_tax" placeholder="请填写所得税扣除" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="XX == 'xin'" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="XX == 'bj'" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <!--变更部门-->
    <el-dialog :title="titleMath" v-model="editVisible" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left;width: 87%;">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <div>
              <el-checkbox-group v-model="checkList2" @change="bindCheckBox">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx.length != 0">已选：1个员工</div>
          <div v-if="Chan_Tonx.length == 0">已选：0个员工</div>
          <div class="tyhh" v-if="Chan_Tonx.length != 0">
            <img :src="Chan_Tonx.full_avatar" alt="">
            <span>{{Chan_Tonx.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div> 
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button type="primary" @click="Close_4()">保存设置</el-button>
        </div>
      </div> 
    </el-dialog>

    <!--选择部门-->
    <el-dialog :title="titleMath" v-model="editVisible1" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left;width: 87%;">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso" ><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="id"
              ref="tree"
              :check-strictly = "true"
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="handleClick"
               @node-click="nodeClick"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>
            </el-tree>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Tonx != null">已选：1个部门</div>
          <div v-if="Bumen_Tonx == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Tonx != null">
            <span>{{Bumen_Tonx.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div> 
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button type="primary" @click="BTN_Bumen()">保存设置</el-button>
        </div>
      </div> 
    </el-dialog>

    <!-- 薪资确认 -->
    <el-dialog title="薪资确认" v-model="editVisible2" width="30%">
      <div class="EditNewForm">
        <div class="EditNewFormBtns">
          <span style="margin-right: 20px;">确认选项</span>
          <el-radio v-model="status" label="20">无问题（默认）</el-radio>
          <el-radio v-model="status" label="30">有异议</el-radio>
        </div>
        <div class="EditNewFormBtns" style="margin-top: 20px;">
          <div class="EditNewFormItemName">问题描述：（选填）</div>
          <div class="EditNewFormItemIpt">
            <el-input type="textarea" v-model="msg" placeholder="您可以将问题详细的描述在这里，财务人员会及时和您沟通"/>
          </div>
        </div>
        <div class="EditNewFormBtns">
          <div class="FormBtns ggg">
            <div class="FormBtnsQuery" @click="Btn_tj()">创建</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import Reconciliationofbills from './components/Reconciliationofbills.vue'
import FundManagement from "./components/fundManagement.vue"
import ControlOverInvoices from "./components/ControlOverInvoices.vue"
export default {
  components:{
    Reconciliationofbills,
    FundManagement,
    ControlOverInvoices
  },
  data(){
    return{
      name: localStorage.getItem("ms_username"),
      tableData:[],
      Chan_Tonx:[],
      Chan_TARR:[],
      DepartmentList:[],
      StaffList:[],
      StaFFList:[],
      Parents:[],
      checkList2:[],
      DMent:[],
      Bumen_Tonx:[],
      activeIndex: "1",
      department_id:'',
      XX:'',
      total:'',
      isShow: 1 ,
      staff_id:'',
      status:'',
      msg:'',
      titleMath:'',
      staffId:'',
      editId:'',
      ruleFormRef2:false,
      editVisible:false,
      editVisible1:false,
      editVisible2:false,
      control:false,
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        keyword: "",
        money: {
          min:"",
          max:"",
        },
        time: "",
        staff_id: "",
      },
      rules:{
        People_jj2: [{ required: true, message: '请选择部门', trigger: 'change' }],
        People_jj3: [{ required: true, message: '请选择职务', trigger: 'change' }],
        staff_id: [{ required: true, message: '请选择客服姓名', trigger: 'change' }],
        time: [{ required: true, message: '选择月份', trigger: 'change' }],
        need_clock_num: [{ required: true, message: '请输入应出勤天数', trigger: 'blur' }],
        go_clock_num: [{ required: true, message: '请输入实际出勤天数', trigger: 'blur' }],
        basic: [{ required: true, message: '请输入基本工资', trigger: 'blur' }],
        subsidy: [{ required: true, message: '请填写综合补贴', trigger: 'blur' }],
        performance: [{ required: true, message: '请填写绩效工资', trigger: 'blur' }],
        percentage: [{ required: true, message: '请填写总提成', trigger: 'blur' }],
        percentage_month: [{ required: true, message: '请填写当月实发提成', trigger: 'blur' }],
        percentage_other: [{ required: true, message: '请填写剩余提成', trigger: 'blur' }],
        bonus: [{ required: true, message: '请填写奖金', trigger: 'blur' }],
        full_attendence_reward: [{ required: true, message: '请填写全勤奖', trigger: 'blur' }],
        deduct_clock: [{ required: true, message: '请填写考勤扣除', trigger: 'blur' }],
        accumulation_fund: [{ required: true, message: '请填写公积金扣除', trigger: 'blur' }],
        insurance: [{ required: true, message: '请填写社保代扣', trigger: 'blur' }],
        deduct_percentage: [{ required: true, message: '请填写提成扣除', trigger: 'blur' }],
        income_tax: [{ required: true, message: '请填写所得税扣除', trigger: 'blur' }],
      },
      Row:{
        People_jj2:'',
        People_jj3:'',
        staff_id:'',
        time:'',
        need_clock_num:'',
        go_clock_num:'',
        basic:'',
        subsidy:'',
        performance:'',
        percentage:'',
        percentage_month:'',
        percentage_other:'',
        bonus:'',
        full_attendence_reward:'',
        deduct_clock:'',
        accumulation_fund:'',
        insurance:'',
        deduct_percentage:'',
        income_tax:'',
      },
    }
  },
  mounted(){
    var that = this
    let type = this.$route.query.isShow;
    if(type == 1){
      this.isShow = 1
      this.activeIndex = 1
    }else if(type == 2){
      this.isShow = 2
      this.activeIndex = 2
    }else if(type == 3){
      this.isShow = 3
      this.activeIndex = 3
    }else if(type == 'undefined'){
      this.isShow = 1
      this.activeIndex = 1
    }
    that.loadTable();
    axios.get('/admin/ajax/staffList',{   // 通讯录选择
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StaFFList = res.data.data
        }
    })      
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/finance/salary/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          keyword: "",
          money: {
            min:"",
            max:"",
          },
          time: "",
          staff_id: "",
        }
      }
      that.loadTable();
    },
    // 创建打开
    showAdd() {
      var that = this
      that.ruleFormRef2 = true;
      that.control =  false
      that.titleMath = '添加'
      that.XX = 'xin'
      that.Row = {};
    },
     //新增
    BtnAdd() {
      var that = this
      var time = that.formatDate(that.Row.time)
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/finance/salary/add',
            data: {
              row: {
                staff_id:that.Chan_Tonx.id,
                time: time,
                need_clock_num: that.Row.need_clock_num,
                go_clock_num: that.Row.go_clock_num,
                basic: that.Row.basic,
                subsidy: that.Row.subsidy,
                performance: that.Row.performance,
                percentage: that.Row.percentage,
                percentage_month: that.Row.percentage_month,
                percentage_other: that.Row.percentage_other,
                bonus: that.Row.bonus,
                full_attendence_reward: that.Row.full_attendence_reward,
                deduct_clock: that.Row.deduct_clock,
                accumulation_fund: that.Row.accumulation_fund,
                insurance: that.Row.insurance,
                deduct_percentage: that.Row.deduct_percentage,
                income_tax: that.Row.income_tax,
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.loadTable();
            }
          })
        }
      })
    },
    //获取编辑内容
    HandleEdit(data) {
      var that = this
      that.ruleFormRef2 = true;
      that.control =  false
      that.titleMath = '编辑'
      that.XX = 'bj'
      that.editId = data.id
      axios.get('/admin/finance/salary/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data.row);
        that.Row = {
          staff_id:res.data.data.row.staff.realname,
          time: res.data.data.row.time,
          need_clock_num: res.data.data.row.need_clock_num,
          go_clock_num: res.data.data.row.go_clock_num,
          basic: res.data.data.row.basic,
          subsidy: res.data.data.row.subsidy,
          performance: res.data.data.row.performance,
          percentage: res.data.data.row.percentage,
          percentage_month: res.data.data.row.percentage_month,
          percentage_other: res.data.data.row.percentage_other,
          bonus: res.data.data.row.bonus,
          full_attendence_reward: res.data.data.row.full_attendence_reward,
          deduct_clock: res.data.data.row.deduct_clock,
          accumulation_fund: res.data.data.row.accumulation_fund,
          insurance: res.data.data.row.insurance,
          deduct_percentage: res.data.data.row.deduct_percentage,
          income_tax: res.data.data.row.income_tax,
          People_jj2: res.data.data.row.department.name,
          People_jj3 : res.data.data.row.staff.job
        }
        that.staffId = res.data.data.row.staff.id
      })
    },
    HandleSee(data){
      var that = this
      that.control =  true
      that.ruleFormRef2 = true;
      that.XX = ''
      that.titleMath = '查看信息'
      that.editId = data.id
      axios.get('/admin/finance/salary/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data.row);
        that.Row = {
          staff_id:res.data.data.row.staff.realname,
          time: res.data.data.row.time,
          need_clock_num: res.data.data.row.need_clock_num,
          go_clock_num: res.data.data.row.go_clock_num,
          basic: res.data.data.row.basic,
          subsidy: res.data.data.row.subsidy,
          performance: res.data.data.row.performance,
          percentage: res.data.data.row.percentage,
          percentage_month: res.data.data.row.percentage_month,
          percentage_other: res.data.data.row.percentage_other,
          bonus: res.data.data.row.bonus,
          full_attendence_reward: res.data.data.row.full_attendence_reward,
          deduct_clock: res.data.data.row.deduct_clock,
          accumulation_fund: res.data.data.row.accumulation_fund,
          insurance: res.data.data.row.insurance,
          deduct_percentage: res.data.data.row.deduct_percentage,
          income_tax: res.data.data.row.income_tax,
          People_jj2: res.data.data.row.department.name,
          People_jj3 : res.data.data.row.staff.job
        }
        that.staffId = res.data.data.row.staff.id
      })
    },
    // 编辑保存
    BtnEdit() {
      var that = this
      var time = that.formatDate(that.Row.time)
      that.$refs['Row'].validate((valid) =>{
        if(valid){
          axios({
            method: 'put',
            url: '/admin/finance/salary/edit',
            data: {
              id: that.editId,
              row: {
                staff_id:that.staffId,
                time: time,
                need_clock_num: that.Row.need_clock_num,
                go_clock_num: that.Row.go_clock_num,
                basic: that.Row.basic,
                subsidy: that.Row.subsidy,
                performance: that.Row.performance,
                percentage: that.Row.percentage,
                percentage_month: that.Row.percentage_month,
                percentage_other: that.Row.percentage_other,
                bonus: that.Row.bonus,
                full_attendence_reward: that.Row.full_attendence_reward,
                deduct_clock: that.Row.deduct_clock,
                accumulation_fund: that.Row.accumulation_fund,
                insurance: that.Row.insurance,
                deduct_percentage: that.Row.deduct_percentage,
                income_tax: that.Row.income_tax,
              }, 
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
              that.ruleFormRef2 = false

            }
          })
        }
      })
    },
    //删除
    HandleDel(data) {
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/finance/salary/del',
          data: {
            id: data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //工资确认
    handSub(data){
      var that = this 
      that.editVisible2 = true
      that.status = '20'
      that.staff_id = data.id
    },
    //工资提交
     Btn_tj() {      
      var that = this
        axios({ 
          method: 'post',
          url: '/admin/finance/salary/confirm',    
          data: {
            id:that.staff_id,
            status:that.status,
            msg:that.msg
          },
        }).then(function (res) { 
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
            that.editVisible2 = false
          }else {
            that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.loadTable()
          }
        })      
    },
    Close() {
      this.ruleFormRef2 = false;
      this.editVisible2 = false;
      this.msg = ''
      this.status = '10'
      this.Row = {}
    },
    //导航选择
    handleSelect(key){
      if(key == 1){
        this.isShow = 1
      }else if(key == 2){
        this.isShow = 2
      }else if(key == 3){
        this.isShow = 3
      }else if(key == 4){
        this.isShow = 4
      }
    },
    // 分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page; 
      that.loadTable();
    },
    // 。。。。。。。。。。。。。。。。。。。。。。。。。。。。员工。。。。。。。。。。。。。。。。。。。。。。。。。。。。
    //员工获取焦点
    Foccewe1() {    
      var that = this
      that.Chan_Tonx = []
      that.Chan_TARR = []
      that.editVisible = true 
      that.titleMath = '选择员工'
      axios.get('/admin/company/staff/subListFilter',{   // 通讯录选择
        params:{
          id: 0
        }
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DepartmentList = res.data.data.departmentList
          that.StaffList = res.data.data.staffList
          that.Parents = res.data.data.parents
        }
      })
    },
    //通讯录下级
    Nice_x(e) {             
      var that = this
      axios.get('/admin/company/staff/subListFilter',{
          params:{
            id: e
          }
        }).then(function (res) {
          if (res.code == 0) {
            alert('请求失败');  
          } else{ 
            that.DepartmentList = res.data.data.departmentList
            that.StaffList = res.data.data.staffList
            that.Parents = res.data.data.parents
          }
      })
    },
    // 通讯录选择
    Bloo_Black(e) {        
      var that = this
      axios.get('/admin/company/staff/subListFilter',{   
        params:{
          id: e
        }
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DepartmentList = res.data.data.departmentList
          that.StaffList = res.data.data.staffList
          that.Parents = res.data.data.parents
        }
      })
    },
    bindCheckBox(value){     //通讯录选择员工   单选
      var that = this
      if(that.checkList2.length > 1){
          that.checkList2.splice(0,1)
      }
      var arr = that.checkList2.toString()
      var brr = that.StaffList
      for (let i = 0; i < brr.length; i++) {
        if (brr[i].id == arr) {
          that.Chan_Tonx = brr[i]
        }
      }
      if (value == '') {
        that.Chan_Tonx = []
      }
    },
    //选择人员确认
    Close_4() {        
      var that = this
      that.Row.staff_id = that.Chan_Tonx.realname
      that.Row.People_jj3 = that.Chan_Tonx.job
      that.editVisible = false;
      that.ruleFormRef2 = false

      setTimeout(function(){
        that.ruleFormRef2 = true
      },1)    
    },
    // 。。。。。。。。。。。。。。。。。。。。。。。。。。。。部门。。。。。。。。。。。。。。。。。。。。。。。。。。。。
    Foccewe2() {
      var that = this
      that.editVisible1 = true 
      that.titleMath = '选择部门'

        axios.get('/admin/company/department/index',{   // 部门列表
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent = res.data.data.rows
              that.department_id = res.data.data.rows[0].id
            }
        })
    },
    handleClick(data,checked, node){  // 勾选变更功能部门内
      var that = this
      that.Bumen_Tonx = data
      console.log(data)
      if(checked == true){
        that.checkedId = data.comcode;
        that.$refs.tree.setCheckedNodes([data]);
      }
    },
    nodeClick(data,checked,node){     // 单选
      this.checkedId = data.comcode
      this.$refs.tree.setCheckedNodes([data]);
    },
    Close_Bumen() {  // 删除已选部门
      var that = this
      that.Bumen_Tonx = null
    }, 
    BTN_Bumen() {   //选择部门确认
      var that = this
      that.ruleFormRef2 = false
      setTimeout(function(){
        that.ruleFormRef2 = true
      },1)
      that.editVisible1 = false
      that.Row.People_jj2 = that.Bumen_Tonx.name
    },  
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  }
}
</script>

<style scoped>
.Salary{
  max-width: 180px !important;
}
.NavLi{
  flex:1;
}
.Ipy{
  margin: 0 20px;
}
.EditNew{
  width: 840px;
}
.EditNewForm{
  padding: 0 24px;
 
}


</style>