<template>
  <div class="Reconciliationofbills_I">
    <!-- 搜索导航 -->
    <div class="searchNav">
      <div class="NavLi">
        <div class="NavLiName">核定月份：</div>
        <div class="NavLiIpt">
          <el-date-picker v-model="search.month.min" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          <span class="timeSpan">至</span>
          <el-date-picker v-model="search.month.max" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
        </div>
        <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
        <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
    </div>


    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入商户订单号">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header">
        <el-table-column  prop="id" label="ID"  width="55" align="center"></el-table-column>
        <el-table-column align="center" prop="pay_time" label="交易时间" style="padding-left:20px"></el-table-column>
        <el-table-column align="center" prop="out_trade_no" label="商户订单号"></el-table-column>
        <el-table-column align="center" prop="transaction_id" label="微信支付单号"></el-table-column>
        <el-table-column align="center" prop="trade_type" label="支付场景"></el-table-column>
        <el-table-column align="center" prop="trade_state" label="交易状态"></el-table-column>
        <el-table-column align="center" prop="total_amount" label="订单金额（元）"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios' 
export default {
  data(){
    return{
      tableData:[],
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        keyword: "",
        month: {
          min:"",
          max:"",
        },
      },
      total:'',
    }
  },
  mounted(){
    var that = this
    that.loadTable();
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/finance/bill/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      if( e == false){
        that.search = {
          keyword: "",
          month: {
            min:"",
            max:"",
          },
        }
      }
      that.page.currentPage = 1;
      that.loadTable();
    },
    // 分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page; 
      that.loadTable();
    },
  }
}
</script>

<style>
</style>